<template>
  <v-list
    :class="`text-left ${featured ? 'deep-purple lighten-5' : ''} ${
      isCurrentPlan ? 'light-green lighten-5' : ''
    }`"
    :max-width="maxWidth"
  >
    <v-list-item
      v-for="(feature, i) in planFeatureDescription[plan.sku]"
      :key="i"
    >
      <v-list-item-icon class="mr-2">
        <v-icon small color="accent">mdi-check</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-subtitle class="text-wrap text-caption">{{
          $ml.get(feature)
        }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { planFeatureDescription } from "@/helpers/productsDetails";
import { STATUS, FEATURED_SKU } from "@/helpers/backendConstants.js";
import { mapGetters } from "vuex";
export default {
  name: "PlanDescription",

  props: {
    plan: { type: Object, required: true },
    maxWidth: { type: String, default: "300" },
  },

  data() {
    return {
      planFeatureDescription,
      featured: this.plan.sku === FEATURED_SKU,
    };
  },

  computed: {
    ...mapGetters(["currentCompanyPlan", "selfCheckoutSubscriptionType"]),

    isCurrentPlan() {
      if (!this.currentCompanyPlan) return false;
      const { plan_key, valid_status, subscription_type } =
        this.currentCompanyPlan;

      return (
        plan_key === this.plan.key &&
        STATUS.ACTIVE == valid_status &&
        this.selfCheckoutSubscriptionType == subscription_type
      );
    },
  },

  methods: {},
};
</script>
<style scoped></style>
