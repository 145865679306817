import axios from "axios";

export async function updateControlDomainStatus(companyDomain) {
  const url = process.env.VUE_APP_CLOUD_FUNCTION_DOMAIN_STATUS;

  let result = false;

  await axios
    .put(url, {
      company_domain: companyDomain,
    })
    .then((response) => {
      result = response;
    })
    .catch((err) => {
      console.warn("Vuex: updateControlDomainStatus()", err);
    });

  return result;
}

export async function getCompanyAddressByCnpj(cnpj) {
  const url = process.env.VUE_APP_CLOUD_FUNCTION_SEARCH_CNPJ;
  return await axios.post(url, { cnpj });
}
