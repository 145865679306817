<template>
  <v-card
    :class="`text-center ${featured ? 'deep-purple lighten-5' : ''} ${
      isCurrentPlan ? 'light-green lighten-5' : ''
    }`"
    flat
    tile
    max-width="400"
    min-height="300"
  >
    <v-card-subtitle
      class="px-0 py-2 ma-0 text-uppercase font-weight-bold caption"
      style="height: 30px !important"
    >
      <div v-if="isCurrentPlan && !isTrial" class="green--text">
        {{ $ml.get("currentPlan") }}
      </div>

      <div v-if="isCurrentPlan && isTrial" class="success--text">
        <v-icon v-text="'mdi-check-decagram'" color="success" size="20" />
      </div>

      <div
        v-else-if="featured && !isCurrentPlan"
        class="d-flex align-center justify-center"
      >
        <v-icon
          small
          class="mr-1"
          color="accent"
          v-text="'mdi-star-circle-outline'"
        />
        <span class="accent--text text-lowercase">
          {{ $ml.get("recommended") }}
        </span>
      </div>
    </v-card-subtitle>

    <!-- PLAN NAME -->
    <v-card-title class="d-flex justify-center break-word pa-0 ma-0">
      <v-card-title class="d-block text-wrap text-truncate">
        {{ plan.name }}
      </v-card-title>
    </v-card-title>

    <v-list-item-title
      class="text-h4 mb-1 font-weight-bold primary--text text--lighten-1 text-uppercase"
    >
      {{ priceByPeriod }}
    </v-list-item-title>
    <v-list-item-subtitle class="">
      {{ period }}
    </v-list-item-subtitle>
    <v-list-item-subtitle
      class="text-body-2 mt-2 font-weight-medium secondary--text text--lighten-2"
    >
      ({{ totalPrice }}
      <span class="text-lowercase">{{ $ml.get("to") }}</span>
      {{ updatedLicenses }} {{ $ml.get("licenses") }})
    </v-list-item-subtitle>

    <v-expand-transition>
      <v-chip
        color="success"
        small
        class="font-weight-bold mx-auto mt-2"
        outlined
        v-if="discountInMoney"
      >
        {{ $ml.get("economyOf") + " " + discountInMoney }}
      </v-chip>
    </v-expand-transition>

    <!-- SELECT PLAN BUTTON -->
    <v-card-text
      :class="`pb-0 pt-4 ${$vuetify.breakpoint.smAndDown ? 'px-6' : 'px-2'}`"
    >
      <v-btn
        elevation="0"
        :large="$vuetify.breakpoint.smAndUp"
        :x-large="$vuetify.breakpoint.xs"
        :disabled="isAnnual"
        color="success"
        :outlined="!featured && isCurrentPlan"
        :class="`mb-4 mt-2 px-8 font-weight-bold text-none text-body-1`"
        @click="
          $emit('select-plan', planDetails);
          sendPlanInterest(planDetails);
        "
      >
        {{ $ml.get("buy") }}
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  getPriceByPeriod,
  getTotalPlanPrice,
  getTotalPlanPriceWithoutDiscount,
} from "@/helpers/billing";
import {
  SUBSCRIPTIONS,
  STATUS,
  FEATURED_SKU,
} from "@/helpers/backendConstants.js";
import { formatMoney } from "@/helpers/utils";
import { mapGetters } from "vuex";
import suiteAPIClient from "@/gateways/suiteapi";

export default {
  name: "UpgradePlanCard",

  components: {},

  props: {
    plan: { type: Object, required: true },
    subscriptionType: { type: String, required: true },
    licenses: { type: [Number, String], default: 0 },
  },

  data() {
    return {
      featured: this.plan.sku === FEATURED_SKU,
      SUBSCRIPTIONS,
      STATUS,
    };
  },

  computed: {
    ...mapGetters([
      "companyInSuite",
      "currentCompanyPlan",
      "usersNumber",
      "selfCheckoutDiscountBySubscripton",
      "selfCheckoutMaxLicenses",
      "selfCheckoutSubscriptionType",
      "hasEndedTrial",
      "currentUser",
    ]),

    isCurrentPlan() {
      if (!this.currentCompanyPlan) return false;
      const { plan_key, valid_status, subscription_type } =
        this.currentCompanyPlan;

      return (
        plan_key === this.plan.key &&
        STATUS.ACTIVE == valid_status &&
        this.selfCheckoutSubscriptionType == subscription_type
      );
    },

    planDetails() {
      return {
        key: this.plan.key,
        subscription_type: this.subscriptionType,
        price: this.planPrice,
        licenses: this.updatedLicenses,
        discount: this.selfCheckoutDiscountBySubscripton[this.subscriptionType],
      };
    },

    priceByPeriod() {
      return formatMoney(
        getPriceByPeriod(
          this.subscriptionType,
          this.planPrice,
          this.selfCheckoutDiscountBySubscripton[this.subscriptionType]
        )
      );
    },

    totalPrice() {
      if (this.planPrice > 0) {
        return formatMoney(
          getTotalPlanPrice(
            this.updatedLicenses,
            this.subscriptionType,
            this.selfCheckoutDiscountBySubscripton[this.subscriptionType],
            this.planPrice
          )
        );
      }
      return this.$ml.get("free");
    },

    discountInMoney() {
      const discount =
        this.selfCheckoutDiscountBySubscripton[this.subscriptionType];

      if (discount > 0 && this.planPrice > 0) {
        const withoutDiscount = getTotalPlanPriceWithoutDiscount(
          this.updatedLicenses,
          this.subscriptionType,
          this.planPrice
        );

        const withDiscout = getTotalPlanPrice(
          this.updatedLicenses,
          this.subscriptionType,
          this.selfCheckoutDiscountBySubscripton[this.subscriptionType],
          this.planPrice
        );

        return formatMoney(
          parseFloat(withoutDiscount) - parseFloat(withDiscout)
        );
      }
      return false;
    },

    /**
     * Return a period based on subscription type, eg.: FLEX -> usuário/mês
     */
    period() {
      const period = {
        FLEX: this.$ml.get("month"),
        ANNUAL: this.$ml.get("year"),
      };

      return `${this.$ml.get("user")}/${period[this.subscriptionType]}`;
    },

    planPrice() {
      return this.plan.price;
    },

    isTrial() {
      return this.currentCompanyPlan
        ? this.currentCompanyPlan.status === STATUS.TRIAL
        : false;
    },

    clientPlanSubscriptioType() {
      return this.currentCompanyPlan
        ? this.currentCompanyPlan.subscriptionType
        : "";
    },

    isAnnual() {
      return this.clientPlanSubscriptioType === SUBSCRIPTIONS.ANNUAL;
    },

    rank() {
      return this.plan.rank;
    },

    planKey() {
      return this.plan.key;
    },

    updatedLicenses() {
      return this.licenses || this.selfCheckoutMaxLicenses || this.usersNumber;
    },
  },

  methods: {
    formatMoney,

    getPriceByPeriod,

    sendPlanInterest(plan) {
      const { name, email } = this.currentUser;
      const payload = {
        max_licenses: plan.licenses,
        subscription_type: plan.subscription_type,
        plan_name: this.plan.name,
        suite_company_key: this.companyInSuite.key,
        user: { name, email },
      };
      const url = "/integrations/plan-interest";

      return suiteAPIClient.post(url, payload);
    },
  },
};
</script>
<style scoped>
.card-border {
  border: 3px solid #eb5b1dc2 !important;
}
.current-plan-border {
  border-width: 3px !important;
  border-style: solid !important;
  border-color: var(--v-success-base) !important;
}
.plan-description {
  height: 60px !important;
}
</style>
