<template>
  <v-toolbar height="70" tile flat :class="`mt-2 pb-2 mb-4`">
    <v-btn
      v-if="!isCellPhoneScreen"
      color="secondary lighten-3"
      icon
      x-large
      class="text-none text-body-1 font-weight-medium elevation-0 rounded-0 px-0"
      @click="registerLogEvent"
    >
      <v-icon v-text="'mdi-arrow-left'" size="24" />
    </v-btn>

    <v-spacer />

    <v-slide-y-transition>
      <v-chip
        label
        v-if="flexWithPartnerDiscount > 0"
        color="success"
        small
        class="px-2 mr-1 font-weight-bold"
      >
        {{ flexWithPartnerDiscount }}% OFF
      </v-chip>
    </v-slide-y-transition>

    <div>
      <v-tabs
        v-model="subscriptionType"
        flat
        tile
        centered
        color="primary lighten-3"
        class="rounded-pill white--text outlined"
        active-class="primary lighten-2 rounded-lg white--text rounded-pill"
        hide-slider
        grow
      >
        <v-tab
          class="px-6 rounded-r-xl text-none text-h6 font-weight-medium py-2"
        >
          {{ $ml.get("monthly") }}
        </v-tab>

        <v-tab
          class="px-6 rounded-l-xl text-none text-h6 font-weight-medium py-2"
        >
          {{ $ml.get("annual") }}
        </v-tab>
      </v-tabs>
    </div>

    <v-chip
      v-if="subscriptionType == 1"
      label
      color="success"
      small
      class="px-2 ml-1 font-weight-bold"
    >
      {{
        annualWithPartnerDiscount > 0
          ? annualWithPartnerDiscount
          : annualDefaultDiscountText
      }}% OFF
    </v-chip>

    <v-spacer />

    <div
      v-if="$vuetify.breakpoint.lgAndUp"
      justify="center"
      class="ma-0 pa-0 mt-5 mb-8"
    >
      <LicensesDialogSelector
        :subscription-type="selfCheckoutSubscriptionType"
        @update="updatedMaxLicenses = $event"
      />
    </div>
  </v-toolbar>
</template>
<script>
import { getAnalytics, logEvent } from "firebase/analytics";
import { mapGetters, mapMutations } from "vuex";
import { SUBSCRIPTIONS } from "@/helpers/backendConstants.js";
import LicensesDialogSelector from "@/components/purchase/LicensesDialogSelector.vue";

export default {
  name: "SubscriptionTypeTabs",

  components: {
    LicensesDialogSelector, // mapeado
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "usersNumber",
      "flexWithPartnerDiscount",
      "annualWithPartnerDiscount",
      "annualDefaultDiscountText",
      "selfCheckoutSubscriptionType",
      "mainDomain",
      "selfCheckoutMaxLicenses",
    ]),

    isCellPhoneScreen() {
      return this.$vuetify.breakpoint.xs;
    },

    updatedMaxLicenses: {
      get() {
        return this.selfCheckoutMaxLicenses;
      },
      set(newVal) {
        if (newVal >= this.usersNumber) {
          this.setSelfCheckoutMaxLicenses(newVal);
        }
      },
    },

    subscriptionType: {
      get() {
        if (this.selfCheckoutSubscriptionType == SUBSCRIPTIONS.FLEX) {
          return 0;
        } else {
          return 1;
        }
      },
      set(value) {
        if (value == 0) {
          this.setSelfCheckoutSubscriptionType(SUBSCRIPTIONS.FLEX);
        } else {
          this.setSelfCheckoutSubscriptionType(SUBSCRIPTIONS.ANNUAL);
        }
      },
    },
  },

  watch: {
    selfCheckoutSubscriptionType() {
      const analytics = getAnalytics();

      logEvent(analytics, "self-checkout", {
        click: `Botão de alternar periodicidade (plano de pagamento)`,
        main_domain: this.mainDomain,
        email: this.currentUser.email,
        description:
          "Clicou no botão switch de alternar periodicidade (plano de pagamento)",
      });
    },
  },

  methods: {
    ...mapMutations([
      "setSelfCheckoutSubscriptionType",
      "setSelfCheckoutMaxLicenses",
    ]),

    registerLogEvent() {
      const analytics = getAnalytics();
      const route = "Home";

      logEvent(analytics, "self-checkout", {
        click: `Botão de voltar para página anterior`,
        main_domain: this.mainDomain,
        email: this.currentUser.email,
        description:
          "Clicou no botão de voltar para página anterior no primeiro passo do self-chekout",
      });

      window.history.length > 1
        ? this.$router.go(-1)
        : this.$router.push({ name: route });
    },
  },
};
</script>
