<template>
  <div class="d-flex justify-center">
    <div
      v-for="(availablePlan, index) in availablePlans"
      :key="index"
      :class="`mx-2 rounded-lg outlined overflow-hidden align-center ${
        index === 0 && !checkIfClientHasThisPlan(availablePlan.key)
          ? 'deep-purple lighten-5'
          : ''
      } ${
        checkIfClientHasThisPlan(availablePlan.key)
          ? 'light-green lighten-5'
          : ''
      }`"
    >
      <UpgradePlanCard
        :subscription-type="subscriptionType"
        :licenses="updatedLicenses"
        :plan="availablePlan"
        :index="index"
        @select-plan="$emit('select-plan', $event)"
      />

      <v-divider></v-divider>

      <PlanDescription :plan="availablePlan" />
    </div>
  </div>
</template>
<script>
import { getAnalytics, logEvent } from "firebase/analytics";
import { STATUS } from "@/helpers/backendConstants.js";
import { mapGetters, mapMutations } from "vuex";

import UpgradePlanCard from "@/components/purchase/selfcheckout/UpgradePlanCard";
import PlanDescription from "@/components/purchase/selfcheckout/PlanDescription";

export default {
  name: "PlanFeaturesExpasion",

  components: {
    UpgradePlanCard,
    PlanDescription,
  },

  props: {
    planRank: { type: Number, default: 0 },
    product: { type: String, required: 0 },
    subscriptionType: { type: String, required: true },
    licenses: { type: Number, default: 0 },
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters([
      "plans",
      "currentCompanyPlan",
      "selfCheckoutSubscriptionType",
      "selfCheckoutMaxLicenses",
      "currentUser",
      "mainDomain",
    ]),

    updatedMaxLicenses: {
      get() {
        return this.selfCheckoutMaxLicenses;
      },
      set(newVal) {
        this.setSelfCheckoutMaxLicenses(newVal);
      },
    },

    updatedLicenses() {
      return this.licenses;
    },

    availablePlans() {
      return this.plans
        .filter((plan) => plan.is_active && plan.is_visible)
        .sort(function (a, b) {
          if (a.short_code === "conecta_suite") {
            return -1;
          }
          if (b.short_code === "conecta_suite") {
            return 1;
          }
          if (a.price < b.price) {
            return -1;
          }
          if (a.price > b.price) {
            return 1;
          }
          return 0;
        });
    },
  },

  watch: {
    updatedMaxLicenses() {
      const analytics = getAnalytics();

      logEvent(analytics, "self-checkout", {
        click: "Troca de número de licenças",
        main_domain: this.mainDomain,
        email: this.currentUser.email,
        description:
          "Troca de número de licenças na modal de troca de licenças da página de self-checkout",
      });
    },
  },

  methods: {
    ...mapMutations(["setSelfCheckoutMaxLicenses"]),

    checkIfClientHasThisPlan(key) {
      if (!this.currentCompanyPlan) return false;
      const { plan_key, valid_status, subscription_type } =
        this.currentCompanyPlan;

      return (
        plan_key === key &&
        STATUS.ACTIVE == valid_status &&
        this.selfCheckoutSubscriptionType == subscription_type
      );
    },
  },
};
</script>
<style scoped></style>
