<template>
  <div>
    <v-form
      class="ma-0"
      ref="formBilling"
      v-model="validBillingFields"
      lazy-validation
      onsubmit="return false;"
    >
      <v-row>
        <v-col cols="12" sm="4" md="3" align-self="start">
          <v-card-text class="font-weight-medium body-2 py-0"
            >CNPJ <span class="red--text">*</span></v-card-text
          >
        </v-col>
        <v-col
          class="text-left py-1"
          align-self="start"
          cols="12"
          sm="8"
          md="9"
        >
          <v-text-field
            color="primary"
            ref="cnpj"
            class="pa-0 my-0 mx-4 body-2"
            :value="editedData.cnpj"
            @input="fetchInfoByCNPJ"
            :rules="cnpjRule"
            v-mask="'##.###.###/####-##'"
            placeholder="CNPJ"
            required
            :disabled="hasCNPJ"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="4" md="3" align-self="start">
          <v-card-text class="font-weight-medium body-2 py-0">
            {{ $ml.get("corporate") }}
            <span class="red--text">*</span></v-card-text
          >
        </v-col>
        <v-col
          class="text-left py-1"
          align-self="start"
          cols="12"
          sm="8"
          md="9"
        >
          <v-text-field
            color="primary"
            ref="rsocial"
            class="pa-0 my-0 mx-4 body-2"
            :value="editedData.corporate_name"
            :placeholder="$ml.get('corporate')"
            required
            disabled
            :loading="loadingAddressFields"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="4" md="3" align-self="start">
          <v-card-text class="font-weight-medium body-2 py-0"
            >{{ $ml.get("zipCode") }}
            <span class="red--text">*</span></v-card-text
          >
        </v-col>
        <v-col
          class="text-left py-1"
          align-self="start"
          cols="12"
          sm="8"
          md="9"
        >
          <v-text-field
            color="primary"
            ref="cep"
            class="pa-0 my-0 mx-4 body-2"
            v-model="editedData.address_postal_code"
            v-mask="'##.###-###'"
            :placeholder="$ml.get('zipCode')"
            disabled
            required
            :loading="loadingAddressFields"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="4" md="3" align-self="start">
          <v-card-text class="font-weight-medium body-2 py-0">
            {{ $ml.get("neighborhood") }}
            <span class="red--text">*</span></v-card-text
          >
        </v-col>
        <v-col
          class="text-left py-1"
          align-self="start"
          cols="12"
          sm="8"
          md="9"
        >
          <v-text-field
            color="primary"
            ref="district"
            class="pa-0 my-0 mx-4 body-2"
            v-model="editedData.district"
            :placeholder="$ml.get('neighborhood')"
            disabled
            required
            :loading="loadingAddressFields"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="4" md="3" align-self="start">
          <v-card-text class="font-weight-medium body-2 py-0"
            >{{ $ml.get("address") }}
            <span class="red--text">*</span></v-card-text
          >
        </v-col>
        <v-col class="text-left py-1" align-self="start" cols="8" sm="5" md="6">
          <v-text-field
            color="primary"
            ref="street"
            class="pa-0 my-0 mx-4 body-2"
            v-model="editedData.address_info"
            :loading="loadingAddressFields"
            :placeholder="$ml.get('address')"
            disabled
            required
          ></v-text-field>
        </v-col>
        <v-col class="text-left py-1" align-self="start" cols="4" sm="3" md="3">
          <v-text-field
            color="primary"
            ref="number"
            class="pa-0 my-0 mx-4 body-2"
            v-model="editedData.address_number"
            :placeholder="$ml.get('number')"
            disabled
            required
            :loading="loadingAddressFields"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="0" sm="4" md="3" align-self="start">
          <v-card-text class="font-weight-medium body-2 py-0">{{
            $ml.get("complement")
          }}</v-card-text>
        </v-col>
        <v-col class="text-left py-1" align-self="start" cols="6" sm="8" md="9">
          <v-text-field
            color="primary"
            ref="complement"
            class="pa-0 my-0 mx-4 body-2"
            v-model="editedData.address_complement"
            :placeholder="$ml.get('complement')"
            disabled
            :loading="loadingAddressFields"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="4" md="3" align-self="start">
          <v-card-text class="font-weight-medium body-2 py-0"
            >{{ $ml.get("city") }}/UF
            <span class="red--text">*</span></v-card-text
          >
        </v-col>
        <v-col class="text-left py-1" align-self="start" cols="8" sm="5" md="6">
          <v-text-field
            color="primary"
            class="pa-0 my-0 mx-4 body-2"
            v-model="editedData.address_city"
            :placeholder="$ml.get('city')"
            disabled
            :loading="loadingAddressFields"
          ></v-text-field>
        </v-col>
        <v-col class="text-left py-1" align-self="start" cols="4" sm="3" md="3">
          <v-text-field
            class="pa-0 my-0 mx-4 body-2"
            v-model="editedData.address_state"
            placeholder="UF"
            disabled
            :loading="loadingAddressFields"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="4" md="3" align-self="start">
          <v-card-text class="font-weight-medium body-2 py-0">
            {{ $ml.get("financeEmail") }}
            <span class="red--text">*</span></v-card-text
          >
        </v-col>
        <v-col
          class="text-left py-1"
          align-self="start"
          cols="12"
          sm="8"
          md="9"
        >
          <v-text-field
            color="primary"
            class="pa-0 my-0 mx-4 body-2"
            type="email"
            v-model="editedData.financial_email"
            :rules="emailRule"
            placeholder="E-mail"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row
        class="d-flex align-center justify-end mb-1 px-4 pt-4"
        style="border-top: 1px solid #e0e0e0"
      >
        <v-btn
          :x-large="!$vuetify.breakpoint.smAndDown"
          elevation="0"
          text
          class="text-none mr-8"
          :disabled="showLoading"
          @click="resetFields"
          >{{ $ml.get("clearFields") }}</v-btn
        >
        <v-btn
          @click="updateCompany"
          :loading="showLoading"
          :x-large="!$vuetify.breakpoint.smAndDown"
          elevation="0"
          :disabled="!canSaveData"
          color="accent"
          type="submit"
          class="text-none white--text"
          >{{ $ml.get("save") }}</v-btn
        >
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import {
  maskCNPJ,
  maskCEP,
  preValidateCNPJ,
  removeCompanyInfoMask,
  validateCnpj,
} from "@/helpers/utils";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { getCompanyAddressByCnpj } from "@/cloud_functions";

export default {
  directives: { mask },

  props: {},

  data: () => ({
    loadingAddressFields: false,
    showLoading: false,
    validBillingFields: true,
    editedData: {
      financial_email: "",
      cnpj: "",
      corporate_name: "",
      address_postal_code: "",
      address_info: "",
      address_number: "",
      address_complement: "",
      address_city: "",
      address_state: "",
      district: "",
    },
  }),

  computed: {
    ...mapGetters(["company", "companyInSuite"]),

    canSaveData() {
      return this.areBillingFieldsValid && this.haveEditedBillingInfo;
    },

    areBillingFieldsValid() {
      const { cnpj, financial_email } = this.editedData;
      const isCnpjValid = validateCnpj(cnpj);
      const isEmailValid = this.validateEmail(financial_email);

      return (
        isCnpjValid && isEmailValid && !this.hasBillingRequiresFieldsNotFilled
      );
    },

    hasBillingRequiresFieldsNotFilled() {
      const fields = [
        "cnpj",
        "address_postal_code",
        "financial_email",
        "address_info",
        "address_number",
        "address_city",
        "address_state",
      ];
      return Object.entries(this.editedData).some(
        (item) => fields.includes(item[0]) && !item[1]
      );
    },

    haveEditedBillingInfo() {
      return Object.entries(this.editedData).some(([key, value]) => {
        return (
          [
            "cnpj",
            "address_postal_code",
            "financial_email",
            "address_info",
            "address_number",
            "address_complement",
            "responsible_email",
          ].includes(key) && value !== this.companyInSuite[key]
        );
      });
    },

    cnpjRule() {
      return [
        (v) => !!v || this.$ml.get("requiredCNPJ"),

        (v) => (v && v.length === 18) || this.$ml.get("insertingCNPJ"),
      ];
    },

    emailRule() {
      return [
        (v) => !!v || this.$ml.get("requiredEmail"),
        // Os finais de e-mail aceitos estão baseados nos clientes ativos do sistema conforme painel do Reseller
        (v) =>
          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(v) ||
          this.$ml.get("invalid_email"),
      ];
    },

    hasCNPJ() {
      return !!this.companyInSuite.cnpj;
    },
  },

  methods: {
    ...mapActions(["updateCompanyInSuite", "setSnackbarDefault"]),

    ...mapMutations(["setCompanyInSuite", "setSelectedClient"]),

    getCompanyAddressByCnpj,
    maskCNPJ,
    maskCEP,
    preValidateCNPJ,
    removeCompanyInfoMask,

    validateEmail(email) {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      return emailRegex.test(email);
    },

    resetFields() {
      this.setEditableBillingData();
    },

    async fetchInfoByCNPJ(cnpj) {
      if (this.hasCNPJ) {
        return;
      }

      if (!preValidateCNPJ(cnpj)) {
        return;
      } else if (preValidateCNPJ(cnpj) && cnpj !== this.editedData.cnpj) {
        this.editedData.cnpj = cnpj;

        this.loadingAddressFields = true;
        this.getCompanyAddressByCnpj(removeCompanyInfoMask("cnpj", cnpj))
          .then(({ data }) => {
            this.assignCompanyAddressByCnpjApi(data);
          })
          .catch((error) => {
            this.setSnackbarDefault({
              message: error.response.data.code,
              show: true,
              color: "error",
            });
            this.clearCnpjAddressFields();
            console.error(error);
          })
          .finally(() => {
            this.loadingAddressFields = false;
            this.$emit("updateBillingInfo", this.editedData);
          });
      }
    },

    setEditableBillingData() {
      this.editedData = {
        cnpj: this.maskCNPJ(this.companyInSuite.cnpj),
        address_postal_code: this.maskCEP(
          this.companyInSuite.address_postal_code
        ),
      };

      [
        "address_info",
        "address_number",
        "address_complement",
        "address_city",
        "address_state",
        "financial_email",
        "district",
        "corporate_name",
        "financial_email",
      ].forEach(
        (value) => (this.editedData[value] = this.companyInSuite[value])
      );
    },

    async updateCompany() {
      if (!this.$refs.formBilling.validate() || !this.canSaveData) {
        this.setSnackbarDefault({
          message: "fillingRequiredFields",
          show: true,
          color: "error",
        });
        return;
      }

      this.showLoading = true;

      const payload = {
        ...this.editedData,
        cnpj: removeCompanyInfoMask("cnpj", this.editedData.cnpj),
        address_postal_code: removeCompanyInfoMask(
          "address_postal_code",
          this.editedData.address_postal_code
        ),
        suite_company_key: this.companyInSuite.key,
      };

      this.updateCompanyInSuite(payload)
        .then(({ data }) => {
          this.setCompanyInSuite(data);
          this.setSnackbarDefault({ message: "dataUpdated" });
        })
        .catch((error) => {
          this.setSnackbarDefault({
            message: "fail_to_update_company_in_suite",
            color: "error",
          });
          console.error("updateCompany()", error);
        })
        .finally(() => {
          this.showLoading = false;
          this.$emit("close");
        });
    },

    assignCompanyAddressByCnpjApi(data) {
      this.editedData["address_postal_code"] = data.cep;
      this.editedData["address_info"] = data.logradouro;
      this.editedData["address_number"] = data.numero;
      this.editedData["address_complement"] = data.complemento;
      this.editedData["address_city"] = data.municipio;
      this.editedData["address_state"] = data.uf;
      this.editedData["corporate_name"] = data.nome;
      this.editedData["district"] = data.bairro;
      this.editedData["cnpj"] = data.cnpj;
    },

    clearCnpjAddressFields() {
      this.editedData = {
        address_postal_code: "",
        address_info: "",
        address_number: "",
        address_complement: "",
        address_city: "",
        address_state: "",
        corporate_name: "",
        district: "",
        cnpj: "",
        financial_email: this.companyInSuite["financial_email"],
      };
    },
  },

  async mounted() {
    this.setEditableBillingData();
  },
};
</script>
