<template>
  <div>
    <v-slide-x-transition>
      <span
        v-if="selfCheckoutDiscount > 0 && selfCheckoutTotalPriceWithoutDiscount"
        class="text-h6 font-weight-light text-decoration-line-through"
      >
        {{ formatMoney(selfCheckoutTotalPriceWithoutDiscount) }}
      </span>
    </v-slide-x-transition>

    <v-card-actions v-if="selfChekoutTotalPrice" class="px-0 align-end">
      <!-- TOTAL -->
      <span class="text-h4 font-weight-bold primary--text text--lighten-2">
        {{ formatMoney(selfChekoutTotalPrice) }}
      </span>

      <!-- período -->
      <span class="text-body-2 ml-1 mb-1">
        {{
          `/${
            selfCheckoutSubscriptionType == "FLEX"
              ? $ml.get("month")
              : $ml.get("year")
          }`
        }}
      </span>
    </v-card-actions>

    <!-- DESCONTO -->
    <v-slide-x-transition>
      <v-chip
        v-if="selfCheckoutDiscount > 0 && selfCheckoutDiscountInMoney"
        color="success"
        class="mb-1 font-weight-bold"
        label
      >
        {{ $ml.get("economyOf") + formatMoney(selfCheckoutDiscountInMoney) }}
      </v-chip>
    </v-slide-x-transition>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { formatMoney } from "@/helpers/utils";

export default {
  name: "TotalPlanPrice",

  data() {
    return {};
  },

  computed: {
    ...mapGetters([
      "selfCheckoutDiscount",
      "selfCheckoutTotalPriceWithoutDiscount",
      "selfChekoutTotalPrice",
      "selfCheckoutSubscriptionType",
      "selfCheckoutDiscountInMoney",
    ]),
  },

  methods: {
    formatMoney,
  },
};
</script>
