<template>
  <v-container
    :class="`${
      $vuetify.breakpoint.mdAndUp ? 'px-5' : 'px-0'
    } fill-height justify-center`"
    style="width: 100%"
  >
    <PurchaseLoading
      :show="showPurchaseLoading"
      :creating-plan="loadingPurchase"
      :created-plan="createdPlan"
      :is-credit-card-payment="isCreditCardPayment"
      :error="setErrorState"
      @reset-created-plan="createdPlan = false"
      @reset-error-state="
        (setErrorState = false), (showPurchaseLoading = false)
      "
      @hide="showPurchaseLoading = false"
    />

    <Alert
      v-if="isSimulation"
      width="100%"
      color="warning"
      class="mx-4 mt-6 py-4"
      dense
    >
      <span v-html="$ml.get('simulationAlert')"></span>
    </Alert>

    <v-stepper
      v-if="!isSimulation && !showHelpWithPayment"
      v-model="steps"
      class="mb-8 elevation-0 pa-0 ma-0"
      style="width: 100%"
    >
      <v-stepper-header class="d-none">
        <v-stepper-step :complete="steps > 1" step="1" />
        <v-stepper-step step="2" />
      </v-stepper-header>

      <v-stepper-items class="px-0 mx-0">
        <v-stepper-content step="1" class="px-0 mx-0">
          <v-row
            class="mx-5 mt-2 mb-6 center"
            justify="center"
            v-if="hasEndedTrial"
          >
            <v-col class="text-center" cols="12">
              <h2
                class="mb-2 text-h4 primary--text text--lighten-2 font-weight-bold"
              >
                {{ $ml.get("trialEnded") }}
              </h2>
              <div class="mt-4">
                {{ $ml.get("trialEndedText") }}
              </div>
            </v-col>
          </v-row>
          <v-card flat tile>
            <PurchasePlansSelector @select-plan="getSelectedPlan" />
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card flat tile>
            <v-row :class="`ma-0 pa-0`">
              <v-col
                :cols="$vuetify.breakpoint.mdAndUp ? 7 : 12"
                :class="`${$vuetify.breakpoint.xs ? 'mx-0 px-0' : ''}`"
              >
                <v-subheader class="font-weight-bold">
                  {{ $ml.get("cart") }}
                </v-subheader>

                <PlanInCartCard @clean:selfcheckout-cart="clearCart" />

                <v-subheader class="font-weight-bold mt-4">
                  {{ $ml.get("companyInfo") }}
                </v-subheader>

                <CompanyInformation />
              </v-col>
              <v-col
                :cols="$vuetify.breakpoint.mdAndUp ? 5 : 12"
                :class="`${$vuetify.breakpoint.xs ? 'mx-0 px-0' : ''}`"
              >
                <SelectPaymentType />

                <!-- PURCHASE INFO AND DATES -->
                <v-subheader class="font-weight-bold">
                  {{ $ml.get("orderDetails") }}
                </v-subheader>

                <v-card outlined>
                  <v-list class="pl-0">
                    <v-list-item
                      v-for="(orderConfig, index) in orderConfigInformation"
                      :key="index"
                    >
                      <v-list-item-content>
                        <v-list-item-subtitle
                          class="caption font-weight-medium break-word"
                        >
                          {{ orderConfig.title }}
                        </v-list-item-subtitle>
                        <v-list-item-title>
                          <div
                            v-if="
                              orderConfig.key === 'init_billing' &&
                              remainingTrialDays &&
                              !nextBillingIsToday
                            "
                            class="text-decoration-line-through grey--text"
                          >
                            {{ orderNextBillingWithoutTrialRemainingDays }}

                            <v-chip
                              color="success"
                              class="font-weight-medium mb-1 ml-1"
                              small
                            >
                              <v-icon v-text="'mdi-check-circle'" small left />
                              {{ remainingTrialDays }}
                            </v-chip>
                          </div>
                          {{ orderConfig.subtitle }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>

                <!-- TOTAL & PAYMENT -->
                <v-card
                  v-if="$vuetify.breakpoint.smAndUp"
                  :color="$vuetify.theme.dark ? '' : 'grey lighten-4'"
                  :class="`mt-4 pa-6 ${
                    $vuetify.breakpoint.smAndDown ? 'mb-5' : ''
                  }`"
                  flat
                >
                  <v-card-title class="pa-0 mb-2">{{
                    $ml.get("total")
                  }}</v-card-title>

                  <TotalPlanPrice />

                  <Alert
                    v-if="totalPriceDoNotAchieveMinValue"
                    color="error"
                    dark
                    dense
                    class="mt-3"
                  >
                    {{ minValueMessage }}
                  </Alert>

                  <div v-if="$vuetify.breakpoint.mdAndUp">
                    <v-btn
                      v-if="selfChekoutTotalPrice"
                      x-large
                      color="accent"
                      elevation="0"
                      block
                      :loading="loadingPurchase"
                      class="px-6 text-none text-h6 font-weight-medium rounded-lg mt-4"
                      :disabled="
                        doNotHaveCNPJ ||
                        !hasFinancialEmail ||
                        totalPriceDoNotAchieveMinValue
                      "
                      @click="verifyContractedProducts"
                    >
                      {{
                        isCreditCardPayment
                          ? $ml.get("goToPayment")
                          : $ml.get("finishBuy")
                      }}
                      <v-icon
                        v-text="'mdi-arrow-right'"
                        class="arrow-left-transition"
                        right
                      />
                    </v-btn>

                    <ErrorReportButtonVue v-else />

                    <v-btn
                      v-if="selfChekoutTotalPrice"
                      large
                      text
                      color="grey darken-1"
                      elevation="0"
                      block
                      :loading="loadingPurchase"
                      class="px-6 text-none text-body-1 font-weight-medium rounded-lg mt-4"
                      :disabled="loadingPurchase"
                      @click="clearCart"
                    >
                      {{ $ml.get("clearCart") }}
                    </v-btn>
                  </div>
                </v-card>
              </v-col>
            </v-row>

            <v-footer
              v-if="$vuetify.breakpoint.smAndDown"
              :fixed="$vuetify.breakpoint.sm"
              class="py-4"
            >
              <v-row class="ma-0 pa-0">
                <v-col cols="12" xs="12" v-if="$vuetify.breakpoint.xs">
                  <v-subheader class="px-0">{{ $ml.get("total") }}</v-subheader>

                  <TotalPlanPrice />

                  <Alert
                    v-if="totalPriceDoNotAchieveMinValue"
                    color="error"
                    dark
                    dense
                    class="mt-3"
                  >
                    {{ minValueMessage }}
                  </Alert>
                </v-col>
                <v-col
                  v-if="$vuetify.breakpoint.smAndUp"
                  cols="12"
                  xs="12"
                  sm="6"
                  :order="$vuetify.breakpoint.xs ? 2 : 1"
                  class="py-1"
                >
                  <v-btn
                    v-if="selfChekoutTotalPrice"
                    color="grey darken-2"
                    :class="`text-none ${
                      $vuetify.breakpoint.smAndDown
                        ? 'text-body-2 my-auto'
                        : 'text-body-1'
                    } font-weight-medium`"
                    :disabled="loadingPurchase"
                    :block="$vuetify.breakpoint.xs"
                    text
                    x-large
                    @click="goBackToPlans"
                  >
                    {{ $ml.get("clearCart") }}
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="6"
                  :order="$vuetify.breakpoint.xs ? 1 : 2"
                  class="py-1 d-flex justify-end"
                >
                  <v-btn
                    v-if="selfChekoutTotalPrice"
                    x-large
                    color="accent"
                    elevation="0"
                    :block="$vuetify.breakpoint.xs"
                    :loading="loadingPurchase"
                    :class="`px-6 text-none x text-body-1 font-weight-medium rounded-pill`"
                    :disabled="doNotHaveCNPJ || totalPriceDoNotAchieveMinValue"
                    @click="verifyContractedProducts"
                  >
                    {{
                      isCreditCardPayment
                        ? $ml.get("goToPayment")
                        : $ml.get("finishBuy")
                    }}
                    <v-icon
                      v-text="'mdi-arrow-right'"
                      class="arrow-left-transition"
                      right
                    />
                  </v-btn>
                  <ErrorReportButtonVue v-else />
                </v-col>
              </v-row>
            </v-footer>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <HeaderDialog
      id="confirm_new_plan"
      :subtitle="$ml.get('confirmNewPlan')"
      :title="selfCheckoutPlanName"
      width="800"
      :show="showConfirmNewPlan"
      :loading="loadingPurchase"
      @action="hireNewPlan"
      @close="closeHiringModal"
      :action-text="actionText"
      close-text="cancel"
      :toolbarMaxHeight="80"
    >
      <template v-slot:body>
        <v-container>
          <p class="ma-0">
            {{
              `${$ml.get(
                "confirmPurchaseText"
              )} ${selfCheckoutPlanName} ${$ml.get("authorizeCharge")}`
            }}
          </p>
        </v-container>
      </template>
    </HeaderDialog>

    <HelpWithPayment
      v-if="showHelpWithPayment"
      :title="paymentTitle"
      :featuredList="paymentHelpFeatureList"
      :is-credit-card-payment="isCreditCardPayment"
      @backToPlans="goBackToPlans"
    />
  </v-container>
</template>
<script>
import { getAnalytics, logEvent } from "firebase/analytics";
import moment from "moment";
import { logBtnEvent } from "@/helpers/registerAnalytics";
import {
  addSomeDays,
  greaterOrEqualToday,
  selfCheckoutToday,
  formatDate,
  ticketExpiration,
  formatDateForDatabase,
  getRemainingTrialDays,
  checkIfDatesAreEqual,
} from "@/helpers/utils";
import { STATUS, SUBSCRIPTIONS } from "@/helpers/backendConstants";
import { updateControlDomainStatus } from "@/cloud_functions/index";
import { mapGetters, mapActions, mapMutations } from "vuex";
import CompanyInformation from "@/components/purchase/selfcheckout/CompanyInformation";
import ErrorReportButtonVue from "@/components/general/ErrorReportButton.vue";
import HelpWithPayment from "@/components/purchase/selfcheckout/HelpWithPayment";
import PlanInCartCard from "@/components/purchase/selfcheckout/PlanInCartCard";
import PurchasePlansSelector from "@/components/purchase/PurchasePlansSelector";
import SelectPaymentType from "@/components/purchase/selfcheckout/SelectPaymentType.vue";
import TotalPlanPrice from "@/components/purchase/selfcheckout/TotalPlanPrice.vue";
import PurchaseLoading from "@/components/purchase/selfcheckout/PurchaseLoading";
import { getDatabase, ref, child, get } from "firebase/database";

export default {
  name: "UpgradePlan",

  components: {
    CompanyInformation,
    ErrorReportButtonVue,
    HelpWithPayment,
    PlanInCartCard,
    PurchasePlansSelector,
    SelectPaymentType,
    TotalPlanPrice,
    PurchaseLoading,
  },

  data() {
    return {
      showConfirmNewPlan: false,
      showHelpWithPayment: false,
      steps: 1,
      loadingPurchase: false,
      selfCheckoutToday,
      selectedPlan: null,
      actionText: this.$ml.get("confirmPlanPurchaseText"),
      createdPlan: false,
      showPurchaseLoading: false,
      setErrorState: false,
    };
  },

  computed: {
    ...mapGetters([
      "hasControlPlan",
      "company",
      "companyInSuite",
      "currentUser",
      "isSimulation",
      "doNotHaveCNPJ",
      "plans",
      "currentSelectedPlan",
      "clientHasThisPlan",
      "usersNumber",
      "companyImpactedPlansInTrial", // mockado
      "selfCheckoutPlan",
      "selfCheckoutMaxLicenses",
      "selfCheckoutSubscriptionType",
      "selfCheckoutDiscount",
      "selfChekoutTotalPrice",
      "selfCheckoutPlanName",
      "temporaryCompanyPlan",
      "selfCheckoutPlanKey",
      "selfCheckoutPlanProducts",
      "companyImpactedPlans", // mockado
      "isCreditCardPayment",
      "hasEndedTrial",
      "isIntrestedInService",
      "maxValueAllowedForCardPayment",
    ]),

    hasFinancialEmail() {
      return !!this.companyInSuite.financial_email;
    },

    totalPriceDoNotAchieveMinValue() {
      return this.selfChekoutTotalPrice < 40;
    },

    minValueMessage() {
      let message = this.$ml.get("paymentMinimumValue");

      if (this.selfCheckoutSubscriptionType === SUBSCRIPTIONS.FLEX) {
        message += this.$ml.get("paymentMinimumValueTextforFlex");
      } else if (
        this.selfCheckoutSubscriptionType === SUBSCRIPTIONS.ANNUAL &&
        this.selfChekoutTotalPrice < 40
      ) {
        message += this.$ml.get("paymentMinimumValueTextforAnnual");
      } else {
        message = "";
      }

      return message;
    },

    paymentHelpFeatureList() {
      if (this.isCreditCardPayment) {
        return [
          {
            title: this.$ml.get("helpCardFeatureListTitle1"),
            subtitle: this.$ml.get("helpCardFeatureListSubtitle1"),
          },
          {
            title: this.$ml.get("helpCardFeatureListTitle2"),
            subtitle: this.$ml.get("helpCardFeatureListSubtitle1"),
          },
          {
            title: this.$ml.get("helpCardFeatureListTitle3"),
            subtitle: this.$ml.get("helpCardFeatureListSubtitle3"),
          },
        ];
      } else {
        return [
          {
            title: this.$ml.get("helpBankSlipFeatureListTitle1"),
            subtitle: this.$ml.get("helpBankSlipFeatureListSubtitle1"),
          },
          {
            title: this.$ml.get("helpBankSlipFeatureListTitle2"),
            subtitle: this.$ml.get("helpBankSlipFeatureListSubtitle2"),
          },
          {
            title: this.$ml.get("helpBankSlipFeatureListTitle3"),
            subtitle: this.$ml.get("helpBankSlipFeatureListSubtitle3"),
          },
        ];
      }
    },

    paymentTitle() {
      return this.isCreditCardPayment ? "almostThere" : "bankSlipPaymentTitle";
    },

    nextBillingIsToday() {
      return checkIfDatesAreEqual(this.orderNextBilling, selfCheckoutToday);
    },

    remainingTrialDays() {
      return getRemainingTrialDays(this.nearestEndTrial[0]);
    },

    ticketExpiration() {
      return ticketExpiration(
        formatDate(this.orderNextBilling),
        this.selfCheckoutSubscriptionType
      );
    },

    orderConfigInformation() {
      let initBillingText = "";

      if (
        this.companyImpactedPlansInTrial.length > 0 &&
        !this.nextBillingIsToday
      ) {
        initBillingText = `${this.$ml.get(
          "getBasicAfterTrialEnded"
        )} ${formatDate(this.orderNextBilling)})`;
      } else {
        initBillingText = formatDate(this.orderNextBilling);
      }

      return [
        {
          title: this.$ml.get("initBilling"),
          key: "init_billing",
          subtitle: initBillingText,
          show: true,
        },
        {
          title: this.$ml.get("bankSlipDueDate"),
          key: "ticket_expiration",
          subtitle: this.ticketExpiration,
          show: !this.isCreditCardPayment,
        },
      ].filter((item) => item.show);
    },

    orderNextBilling() {
      if (this.nearestEndTrial.length) {
        return greaterOrEqualToday(this.nearestEndTrial[0])
          ? formatDateForDatabase(addSomeDays(this.nearestEndTrial[0], 1))
          : formatDateForDatabase(selfCheckoutToday);
      }

      return formatDateForDatabase(selfCheckoutToday);
    },

    orderNextBillingWithoutTrialRemainingDays() {
      return formatDate(selfCheckoutToday);
    },

    nearestEndTrial() {
      return this.impactedCompanyPlansInTrial
        .map((companyPlan) => companyPlan.end_trial)
        .sort((a, b) => new Date(b) - new Date(a));
    },

    impactedCompanyPlansInTrial() {
      return this.companyImpactedPlansInTrial.filter(
        (companyPlan) => companyPlan.status === STATUS.TRIAL
      );
    },

    hasTemporaryCompanyPlan() {
      return Object.keys(this.temporaryCompanyPlan).length > 0;
    },
  },

  watch: {
    plans() {
      if (this.$route.query.plan) {
        this.selectPlan(this.$route.query.plan);
      }
    },

    selfCheckoutSubscriptionType() {
      if (this.selfCheckoutSubscriptionType === SUBSCRIPTIONS.FLEX) {
        this.setSelfCheckoutMaxLicenses(this.usersNumber);
      }
    },

    steps() {
      if (this.steps === 1) {
        this.setSelfCheckoutPlanInCart(null);
      }
    },
  },

  methods: {
    ...mapActions([
      "getPlans",
      "getContractedPlans",
      "hireCompanyPlan",
      "setSnackbarDefault",
    ]),

    ...mapMutations([
      "setSelectedPlanKSKU",
      //"setSidebarMini",
      "setSelfCheckoutPlanInCart",
      "setSelfCheckoutMaxLicenses",
      "setSelfCheckoutPaymentMethod",
      "setCompany",
      "setCompanyInSuite",
      "setMaxValueAllowedForCardPayment",
    ]),

    formatDateForDatabase,

    getMaximunPriceForCreditCard() {
      if (this.maxValueAllowedForCardPayment) {
        return;
      }

      const dbRef = ref(getDatabase());
      get(child(dbRef, `maxValueAllowedForCardPayment`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            this.setMaxValueAllowedForCardPayment(snapshot.val());
          } else {
            this.setDefaultMaxValueCardPayment();
          }
        })
        .catch((error) => {
          console.error(error);
          this.setDefaultMaxValueCardPayment();
        });
    },

    verifyContractedProducts() {
      logBtnEvent({
        label: "Finalizar compra",
        action: "open_hire_modal",
      });

      this.logAnalyticsEventOnFirebase(
        "Finalizar compra",
        "Botão 'Finalizar compra' da página de self-checkout"
      );

      this.showConfirmNewPlan = true;
    },

    setDefaultMaxValueCardPayment() {
      const defaulValue = 500;
      this.setMaxValueAllowedForCardPayment(defaulValue);
    },

    logAnalyticsEventOnFirebase(where, description = "") {
      const analytics = getAnalytics();

      logEvent(analytics, "self-checkout", {
        click: where,
        main_domain: this.mainDomain,
        email: this.currentUser.email,
        description: description,
      });
    },

    clearCart() {
      const analytics = getAnalytics();
      const { main_domain } = this.company;
      const clicked_where = "clear cart";
      const user = this.currentUser.email;

      logEvent(analytics, "billing_upgrade", {
        main_domain,
        user,
        clicked_where,
        date: moment().format("DD/MM/YYYY"),
      });
      this.abandonedCart();
    },

    closeHiringModal() {
      this.logAnalyticsEventOnFirebase(
        "Cancelar upgrade",
        "Cancelamento da modal de upgrade (quando é troca de plano) dentro do self-checkout"
      );

      this.showConfirmNewPlan = false;
    },

    getSelectedPlan(plan_details) {
      const { key } = plan_details;

      const planDetails = this.plans.find((plan) => plan.key === key);

      this.setSelfCheckoutPlanInCart(planDetails);
      this.selectPlan(planDetails.sku);
    },

    abandonedCart() {
      const analytics = getAnalytics();

      if (this.selfCheckoutPlan) {
        const { price, name } = this.selfCheckoutPlan;

        logEvent(analytics, "choose_plan_periodicity", {
          action: "select_plan_periodicity_in_step_two",
          plan_name: name,
          plan_price: price,
          discount: this.selfCheckoutDiscount,
          total_price: this.selfChekoutTotalPrice,
          subscription_type: this.selfCheckoutSubscriptionType,
          status: status,
          licenses: this.usersNumber,
          max_licenses: this.selfCheckoutMaxLicenses,
          hiring_step: this.steps,
          main_domain: this.company.main_domain,
        });
      }

      this.logAnalyticsEventOnFirebase(
        "Abandonar carrinho",
        "Botão 'Abandonar carrinho' dentro da modal de desistência da página de self-checkout"
      );

      this.goBackToPlans(false);
    },

    goBackToPlans(show_event = true) {
      if (show_event) {
        this.logAnalyticsEventOnFirebase(
          "Voltar para listagem de planos",
          "Redirecionamento após desistência/agendamento para o passo 1 da página de self-checkout"
        );
      }

      this.steps = 1;
      this.showHelpWithPayment = false;
      this.setSelfCheckoutPaymentMethod("ticket");
    },

    selectPlan(plan_sku) {
      this.setSelectedPlanKSKU(plan_sku);

      if (this.clientHasThisPlan) {
        this.selectedPlan = this.clientHasThisPlan;
      } else {
        this.selectedPlan = this.currentSelectedPlan;
      }

      const { name, price } = this.selectedPlan;

      const analytics = getAnalytics();

      logEvent(analytics, "choose_plan", {
        action: "select_plan_in_step_one",
        plan_name: name,
        plan_price: price,
        is_new_plan: this.clientHasThisPlan ? false : true,
        main_domain: this.company.main_domain,
      });

      this.steps++;
    },

    hireNewPlan(has_impacted_plan = false) {
      logBtnEvent("action", {
        label: has_impacted_plan ? this.actionText : "Confirmar Pedido",
        action: "hire_plan",
      });

      let payload = {
        plan_key: this.selfCheckoutPlanKey,
        subscription_type: this.selfCheckoutSubscriptionType,
        has_intrest_in_service: this.isIntrestedInService,
        is_bank_slip: !this.isCreditCardPayment,
        suite_company_key: this.companyInSuite.key,
        user: this.currentUser,
      };

      if (this.selfCheckoutSubscriptionType === SUBSCRIPTIONS.ANNUAL) {
        payload["max_licenses"] = parseInt(this.selfCheckoutMaxLicenses);
      }

      this.loadingPurchase = true;
      this.showPurchaseLoading = true;
      this.showConfirmNewPlan = false;

      this.hireCompanyPlan(payload)
        .then(({ data }) => {
          this.setCompanyInSuite(data);
          this.createdPlan = true;
          this.loadingPurchase = false;
          updateControlDomainStatus(this.company.main_domain);
          if (!this.setErrorState) {
            this.showHelpWithPayment = true;
          } else {
            this.$router.push({ name: "Home" });
          }
        })
        .catch((err) => {
          const { data } = err.response;
          console.error("hireNewPlan: ", err);
          this.setSnackbarDefault({
            color: "error",
            message: data.code,
            timeout: data.code === "DOWNGRADE_BLOCK" ? "10000" : "",
            show: true,
          });
          this.showPurchaseLoading = false;
          this.loadingPurchase = false;
        })
        .finally(() => {
          this.selectedPlan = null;
          this.loadingPurchase = false;
          this.showConfirmNewPlan = false;
        });
    },
  },

  async beforeMount() {
    this.setSelfCheckoutMaxLicenses(this.usersNumber);
    if (this.$vuetify.breakpoint.mdAndUp) {
      //this.setSidebarMini(true);
    }
    await this.getPlans();
    this.getMaximunPriceForCreditCard();
  },
};
</script>
<style scoped>
.steps {
  max-width: 800px !important;
}
.purchased-cards {
  border-right: 1px solid #ccc !important;
}
.purchased-cards:last-child {
  border-right: 0px !important;
}
.list-plans {
  display: flex;
  justify-content: center;
}
@media (max-width: 763px) {
  .list-plans {
    display: block;
  }
  .purchased-cards {
    border: 1px solid #ccc !important;
    margin: 0 0 10px 0;
    width: 100% !important;
    border-radius: 8px !important;
  }
  .purchased-cards:last-child {
    border-right: 1px solid #ccc !important;
  }
}
</style>
