const SEC_SKU = "SEC-052024";
const ENT_SKU = "ENT-052024";

export const planFeatureDescription = {
  [SEC_SKU]: [
    "security_availability",
    "security_accessControl",
    "security_customShifts",
    "security_deviceDisconnect",
    "security_loginReport",
    "security_fileSharingReport",
    "security_customLogin",
  ],
  [ENT_SKU]: [
    "enterprise_availability",
    "enterprise_securityBenefits",
    "enterprise_seniorIntegration",
    "enterprise_vrGenteIntegration",
    "enterprise_tangerino",
    "enterprise_activeDirectory",
    "enterprise_otherIntegrations",
  ],
};
