<template>
  <div>
    <v-card outlined @click="editCompanyProfile = true">
      <Alert v-if="doNotHaveCNPJ" color="error" class="mx-2 mt-2 mb-0" dense>
        <span v-html="$ml.get('missingCNPJAlert')"></span>
      </Alert>
      <v-list-item link class="pa-0 ma-0">
        <v-row class="ma-0 py-2 px-1">
          <v-col
            cols="12"
            class="py-2"
            v-for="(info, index) in information"
            :key="index"
          >
            <div
              :class="`px-0 font-weight-medium caption text-wrap ${
                !info.emptyValue ? 'error--text' : ''
              }`"
              v-text="info.title"
            ></div>
            <div
              :class="`px-0 text-body-2 ${
                !info.emptyValue ? 'error--text font-weight-medium' : ''
              }`"
            >
              {{ info.value }}
            </div>
          </v-col>
        </v-row>
        <v-list-item-action class="pr-4">
          <v-icon>mdi-pencil</v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-card>

    <!-- COMPANY PROFILE FORM DIALOG -->
    <HeaderDialog
      :title="$ml.get('billingInfo')"
      :show="editCompanyProfile"
      readOnly
      width="800"
      @close="closeCompanyProfileEditor"
    >
      <template v-slot:body>
        <CompanyBillingInfo
          :isSelfCheckout="true"
          @close="closeCompanyProfileEditor"
        />
      </template>
    </HeaderDialog>

    <!-- ADVICE NO PROFILE COMPANY PERMISSION DIALOG -->
    <!-- <AdvicePermissionDialog
      :show="showNotAllowedAdvice"
      @close="showNotAllowedAdvice = false"
      :title="$t('advicePermissionDialog.companyInfoTitle')"
      :message="$t('advicePermissionDialog.companyInfoMessage')"
    /> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { maskCNPJ } from "@/helpers/utils";

// import AdvicePermissionDialog from "@/components/home/dialogs/AdvicePermissionDialog";
import CompanyBillingInfo from "@/components/billing/upgrade/companyBillingInfo";

export default {
  name: "CompanyInformation",

  components: { /* AdvicePermissionDialog, */ CompanyBillingInfo },

  data() {
    return {
      editCompanyProfile: false,
      // showNotAllowedAdvice: false,
    };
  },

  computed: {
    ...mapGetters(["companyInSuite", "doNotHaveCNPJ"]),

    cnpjNumber() {
      return this.companyInSuite.cnpj;
    },

    corporateName() {
      return this.companyInSuite.corporate_name;
    },

    financialEmail() {
      return this.companyInSuite.financial_email;
    },

    information() {
      return [
        {
          title: "CNPJ",
          emptyValue: !!this.cnpjNumber,
          value: this.cnpjNumber
            ? maskCNPJ(this.cnpjNumber)
            : this.$ml.get("notRegistered"),
        },
        {
          title: this.$ml.get("corporate"),
          emptyValue: !!this.corporateName,
          value: this.corporateName || this.$ml.get("notRegistered"),
        },
        {
          title: this.$ml.get("financeEmail"),
          emptyValue: !!this.financialEmail,
          value: this.financialEmail || this.$ml.get("notRegistered"),
        },
      ];
    },
  },

  methods: {
    maskCNPJ,

    closeCompanyProfileEditor() {
      this.editCompanyProfile = false;
    },
  },
};
</script>
