<template>
  <div>
    <div>
      <span :class="`text-h5 ${titleClass}`">
        {{ formatMoney(selfCheckoutPriceByPeriod) }}

        <span
          :class="`text-body-2 ${subtitleClass}`"
          v-if="$vuetify.breakpoint.smAndUp && inline"
        >
          {{
            `${$ml.get("user")}/${
              selfCheckoutSubscriptionType == "FLEX"
                ? $ml.get("month")
                : $ml.get("year")
            }`
          }}
        </span>

        <v-chip
          v-if="selfCheckoutDiscount > 0"
          small
          color="green lighten-4"
          class="px-1 ml-2 mb-1 green--text text--darken-4 font-weight-bold"
          label
        >
          {{ discountText }}
        </v-chip>
      </span>
    </div>
    <div
      :class="`text-body-2 ${subtitleClass}`"
      v-if="$vuetify.breakpoint.xs && !inline"
    >
      {{
        `${$ml.get("user")}/ ${
          selfCheckoutSubscriptionType == "FLEX"
            ? $ml.get("month")
            : $ml.get("year")
        }`
      }}
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { formatMoney, hasDecimal } from "@/helpers/utils";

export default {
  name: "PriceByUserPerSubscription",

  props: {
    inline: { type: Boolean, default: false },
    titleClass: { type: String, default: "" },
    subtitleClass: { type: String, default: "" },
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters([
      "selfCheckoutPriceByPeriod",
      "selfCheckoutSubscriptionType",
      "selfCheckoutDiscount",
    ]),

    discountText() {
      if (this.selfCheckoutDiscount > 0) {
        let discount = parseFloat(this.selfCheckoutDiscount) * 100 || 0;

        if (hasDecimal(discount)) {
          return `-${discount.toFixed(2)}%`;
        }
        return `${parseInt(discount)}%`;
      }
      return "";
    },
  },

  methods: {
    formatMoney,

    hasDecimal,
  },
};
</script>
