<template>
  <div :style="`max-width: 155px`">
    <label
      v-if="label"
      :class="`caption font-weight-medium text--lighten-2 ${labelClass}`"
    >
      {{ $ml.get(label) }}
    </label>
    <v-card-actions class="align-center number-incrementer px-0 ma-0">
      <v-btn
        :color="color"
        fab
        x-small
        elevation="0"
        class="font-weight-bold"
        :disabled="number <= min || disabled"
        @click="number--"
      >
        <v-icon v-text="'mdi-minus'" :disabled="disabled" />
      </v-btn>
      <v-text-field
        v-model.number="number"
        outlined
        :style="`min-width: 80px; max-width: ${maxWidth}`"
        class="mx-2"
        :placeholder="placeholder"
        :min="min"
        :max="max"
        :rules="min ? [rules.minValue] : []"
        :disabled="disabled"
        type="number"
        hide-spin-buttons
        hide-details
        dense
        @change="verifyIfHasToReset"
      />
      <v-btn
        :color="color"
        fab
        x-small
        elevation="0"
        class="font-weight-bold"
        :disabled="disabled"
        @click="number++"
      >
        <v-icon v-text="'mdi-plus'" :disabled="disabled" />
      </v-btn>
    </v-card-actions>
  </div>
</template>
<script>
export default {
  name: "ButtonNumberIncrementer",

  props: {
    label: { type: String, default: "" },
    value: { type: [String, Number], default: 0 },
    min: { type: [String, Number, Boolean], default: 0 },
    minRuleLabel: {
      type: String,
      default: "decreaseLicenseRule",
    },
    max: { type: [String, Number, Boolean], default: false },
    placeholder: { type: String, default: "" },
    maxWidth: { type: [Number, String], default: "100px" },
    labelClass: { type: String, default: "" },
    defaultValue: { type: [String, Number], default: 0 },
    disabled: { type: Boolean, default: false },
    color: { type: String, default: "accent" },
  },

  data() {
    return {
      number: 0,
      rules: {
        minValue: (e) =>
          (e && e >= this.min) || this.$ml.get(this.minRuleLabel),
      },
    };
  },

  watch: {
    number() {
      this.$emit("update", this.number);
    },

    value() {
      if (this.value !== this.number) {
        this.number = this.value;
      }
    },
  },

  methods: {
    async verifyIfHasToReset(value) {
      if (this.min && value < this.min) {
        await this.setMinimunValue();
      }
    },

    setMinimunValue() {
      this.number = this.min;
    },
  },

  beforeMount() {
    if (this.value) {
      this.number = this.value;
    }
  },
};
</script>
<style>
.number-incrementer input {
  text-align: center !important;
}
</style>
